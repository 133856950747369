import React from "react";

export function Dialog({ open, children }) {
  return open ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full sm:max-w-md max-w-[90%]">
        {children}
      </div>
    </div>
  ) : null;
}

export function DialogContent({ children, className }) {
  return <div className={`p-6 ${className}`}>{children}</div>;
}

export function DialogHeader({ children }) {
  return <div className="border-b p-4">{children}</div>;
}

export function DialogTitle({ children }) {
  return <h2 className="text-xl font-semibold">{children}</h2>;
}