import ProtectedRoute from "@tmavs/reactthings/lib/components/ProtectedRoute";
import { createBrowserRouter, Navigate } from "react-router-dom";
import LoginForm from "./common/containers/Auth/LoginForm";
import PasswordReset from "./common/containers/Auth/PasswordReset";
import Signup from "./common/containers/Auth/Signup";
import BillingPage from "./common/pages/BillingPage";
import LogoutPage from "./common/pages/LogoutPage";
import RoutesPage from "./pages/RoutesPage";

import Home from "./pages/LandingPage";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/password-reset",
    element: <PasswordReset />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/my",
        element: <Navigate to="/my/routes" replace />,
      },
      {
        path: "/my/routes",
        element: <RoutesPage />,
      },
      {
        path: "/my/routes/:routeId",
        element: <RoutesPage />,
      },
      {
        path: "/billing",
        element: <BillingPage />,
      },
    ],
  },
  {
    path: "*",
    element: <p>404 Error - Nothing here...</p>,
  },
]);

export default router;
