// src/components/Sidebar.js
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { viewMyItem } from "../utils/Links";

export default function Sidebar({ title, items, children }) {
  const navigate = useNavigate();

  return (
    <div className={`z-30 w-64 bg-white shadow-md p-4`}>
      <h2 className="text-2xl font-bold mb-4 text-indigo-600">{title}</h2>
      <ul className="space-y-2">
        {items.map((item) => (
          <li
            key={item.id}
            className="flex flex-wrap items-start justify-between p-2 bg-gray-100 rounded-md shadow-sm relative"
          >
            <Button
              variant="ghost"
              className="flex-grow justify-start text-left break-words"
              onClick={() => {
                navigate(viewMyItem(item.id));
              }}
            >
              <span className="break-words">{item.name}</span>
              {item.isPublic && (
                <GlobeAltIcon className="ml-2 h-4 w-4 flex-shrink-0" />
              )}
            </Button>
          </li>
        ))}
      </ul>
      {children}
    </div>
  );
}
