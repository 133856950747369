import React from 'react';

// Constants
const PRICING_HEADING = "Fair and Simple Pricing";
const PRICING_DESCRIPTION = 
  "Let Datatruck take the hassle out of your day. \n\n Start free, and only pay when you need to send more.";

const PRICING_CTA_SUB = 
  "Send up to 50 items every month on us. Upgrade when you’re ready to do more.";

const PRICING_INCLUDED_FEATURES = [
  '50 free deliveries every month',
  'Effortlessly organise and send your forms, files, or images',
  'AI that takes care of the hard work for you',
  'Block unwanted junk and focus on what matters',
  'Deliver anywhere—email, workspace, or tool',
];

const PRICING_AMOUNT = "$2.99 p/m";
const PRICING_UNIT = "first 50 free then $0.02 per delivery";

const PRICING_CTA_TEXT = "Start trial";
const PRICING_CTA_LINK = "/signup"; // Replace with your actual signup URL

// Component
const SingleCardPricing = () => {
    return (
      <section id="pricing" className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        {/* Gradient Background */}
        <div
          className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
  
        {/* Pricing Section Content */}
        <div className="max-w-7xl mx-auto px-6 lg:px-8  rounded-lg">
          <div className="max-w-4xl mx-auto sm:text-center">
            <h2 className="text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
              {PRICING_HEADING}
            </h2>
            <p className="mx-auto mt-6 max-w-2xl text-lg font-medium text-gray-500 sm:text-xl/8">
              {PRICING_DESCRIPTION}
            </p>
          </div>
  
          {/* Pricing Details */}
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl bg-white ring-1 ring-gray-200 sm:mt-20 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <p className="mt-6 text-base leading-7 text-gray-600">
                {PRICING_CTA_SUB}
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                  What’s included
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {PRICING_INCLUDED_FEATURES.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-indigo-600"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
  
            {/* Call-to-Action Card */}
            <div className="-mt-2 p-4 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-white py-12 text-center shadow-md ring-1 ring-gray-200 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-6 lg:px-8">
                  <p className="mt-4 text-center">
                    <span className="block text-5xl font-extrabold tracking-tight text-gray-900">
                      {PRICING_AMOUNT}
                    </span>
                    <span className="block text-lg font-medium leading-6 tracking-wide text-gray-600 mt-2">
                      {PRICING_UNIT}
                    </span>
                  </p>
                  <a
                    href={PRICING_CTA_LINK}
                    className="mt-8 inline-block w-full rounded-md bg-indigo-600 px-4 py-3 text-center text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {PRICING_CTA_TEXT}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

export default SingleCardPricing;