import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirebase } from "../common/contexts/FirebaseContext";
import { Button } from "../ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/Dialog";
import { Input } from "../ui/Input";
import { MAIN_COLLECTION_NAME } from "../utils/FirebaseUtils";

const MODE_OFF = "off";

export default function EditRouteModal({
  objectName = "Route",
  isOpen,
  onClose,
  collection: collectionData,
  onSaveCollection,
  onDeleteCollection,
}) {
  const { firestore } = useFirebase();
  const [name, setName] = useState(collectionData?.name || "");
  const isDeletable =
    !collectionData?.mode || collectionData?.mode === MODE_OFF || false;

  useEffect(() => {
    if (collectionData) {
      setName(collectionData.name || "");
    }
  }, [collectionData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!collectionData) {
      console.error("No data available -- cannot edit.");
      return;
    }

    const updatedCollection = {
      id: collectionData.id,
      name,
    };

    try {
      const collectionRef = doc(
        firestore,
        MAIN_COLLECTION_NAME,
        collectionData.id
      );
      await updateDoc(collectionRef, updatedCollection);
      onSaveCollection(updatedCollection);
      onClose();
    } catch (error) {
      console.error("Error updating collection:", error);
    }
  };

  const handleDeleteClick = () => {
    if (isDeletable) {
      onDeleteCollection(collectionData.id);
      onClose();
    }
  };

  const truncateUrl = (url) => {
    const maxLength = 25; // Customize as needed
    return url.length > maxLength ? `${url.slice(0, maxLength)}...` : url;
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className="sm:max-w-[450px] p-8 space-y-8">
        <DialogHeader className="p-0">
          <DialogTitle className="text-lg font-semibold text-left">
            Edit {objectName}
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Collection Name Field with Character Countdown */}
          <div className="relative">
            <label
              htmlFor="name"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              {objectName} Name <span className="text-red-500">*</span>
            </label>
            <Input
              id="name"
              name="name"
              required
              aria-required="true"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={21}
              className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
            />
            <p className="text-xs text-gray-500 mt-1 text-right">
              {21 - name.length} characters remaining
            </p>
          </div>

          {/* Buttons Row */}
          <div className="flex flex-col items-start mt-6">
            <div className="flex items-center justify-between w-full">
              {/* Delete button */}
              <Button
                type="button"
                variant="destructive"
                onClick={handleDeleteClick}
                disabled={!isDeletable}
                className={`px-4 py-2 ${
                  !isDeletable ? "cursor-not-allowed opacity-50" : ""
                }`}
              >
                Delete
              </Button>

              {/* Close and Update buttons */}
              <div className="flex space-x-4">
                <Button variant="ghost" onClick={onClose}>
                  Close
                </Button>
                <Button type="submit">Update</Button>
              </div>
            </div>

            {/* Text directly under the delete button */}
            {!isDeletable && (
              <p className="text-xs text-gray-500 mt-2">
                Only deletable if the route is <strong>OFF</strong>.
              </p>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
