import Panel from "@tmavs/reactthings/lib/layouts/Panel";
import { httpsCallable } from "firebase/functions";
import React, { useReducer } from "react";
import { useFirebase } from "../common/contexts/FirebaseContext";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";

const MODE_PRODUCTION = "prod";
const MODE_TEST = "test";
const MODE_OFF = "off";

const reducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_MODE_CHANGE":
      return { ...state, modeChanging: true };
    case "MODE_CHANGED":
      return { ...state, modeChanging: false };
    case "SET_NEW_SOURCE":
      return { ...state, newSource: action.payload };
    case "CLEAR_NEW_SOURCE":
      return { ...state, newSource: "" };
    default:
      return state;
  }
};

export default function APIDetailsContainer({
  className = "",
  route,
  updateRoute,
  addSource,
}) {
  const publicUrl = "https://load.datatruck.cc/to/" + route.id;

  const initialState = {
    modeChanging: false,
    newSource: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const deleteSource = (sourceToDelete) => {
    const updatedSources = route.trustedSources.filter(
      (source) => source !== sourceToDelete
    ); // Remove source
    updateRoute({ ...route, trustedSources: updatedSources }); // Update route with new trusted sources
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(publicUrl);
    alert("URL copied to clipboard!");
  };

  const { functions, auth } = useFirebase();

  const toggleMode = async (routeId, newMode) => {
    const user = auth.currentUser;
    if (!user) {
      alert("You must be logged in to toggle mode.");
      return;
    }

    try {
      const fbFunction = httpsCallable(functions, "toggleMode");
      const result = await fbFunction({ routeId, newMode });
      console.log("result is" + JSON.stringify(result));
      if (!result.data || result.data.error) {
        const error = !result.data
          ? "Did not get a valid response"
          : result.data.message;
        throw new Error(error);
      }
      console.log(result.data.message);
      return true; // This will return the updated status or an error message
    } catch (error) {
      console.error("Error toggling mode status:", error.message);
      alert(error.message); // Show error to the user if there's an issue
      return false;
    }
  };

  const handleToggleMode = async (value) => {
    dispatch({ type: "REQUEST_MODE_CHANGE" });
    const result = await toggleMode(route.id, value);
    dispatch({ type: "MODE_CHANGED" });
    if (result) {
      console.log(`Test mode for route ${route.id} changed to:`, value);
    }
  };

  return (
    <Panel header="API Details" className={className}>
      <div className="mb-6">
        <p className="text-sm font-medium text-gray-700">URL:</p>
        <div className="flex items-center justify-between p-2 bg-gray-100 rounded w-full">
          <span className="text-gray-600 truncate">{publicUrl}</span>
          <Button
            variant="ghost"
            size="sm"
            onClick={handleCopy}
            className="text-indigo-600 hover:text-indigo-800"
          >
            Copy
          </Button>
        </div>
      </div>

      <div className="mb-6">
        <p className="text-sm font-medium text-gray-700">Credits Consumed:</p>
        <span className="text-gray-800 text-lg font-semibold">
          {route.creditsUsed || 0}
        </span>
      </div>

      {/* Mode Selection */}
      <div className="mb-6">
        <p className="text-sm font-medium text-gray-700 mb-2">Mode:</p>
        <div className="flex gap-2">
          <Button
            onClick={() => handleToggleMode(MODE_OFF)}
            variant={route.mode === MODE_OFF ? "default" : "ghost"}
            className={`px-4 py-2 ${
              route.mode === MODE_OFF
                ? "bg-indigo-600 text-white"
                : "bg-gray-100 text-gray-800"
            }`}
            disabled={state.modeChanging}
          >
            Off
          </Button>
          <Button
            onClick={() => handleToggleMode(MODE_TEST)}
            variant={
              route.mode === MODE_TEST ||
              (!route.mode && !route.lastDataInTimeStamp)
                ? "default"
                : "ghost"
            }
            className={`px-4 py-2 ${
              route.mode === MODE_TEST ||
              (!route.mode && !route.lastDataInTimeStamp)
                ? "bg-indigo-600 text-white"
                : "bg-gray-100 text-gray-800"
            }`}
            disabled={state.modeChanging}
          >
            Test Mode
          </Button>
          <Button
            onClick={() => handleToggleMode(MODE_PRODUCTION)}
            variant={route.mode === MODE_PRODUCTION ? "default" : "ghost"}
            className={`px-4 py-2 ${
              route.mode === MODE_PRODUCTION
                ? "bg-red-600 text-white"
                : "bg-gray-100 text-gray-800"
            }`}
            disabled={state.modeChanging}
          >
            Production Mode
          </Button>
        </div>
        {state.modeChanging && (
          <p className="text-sm text-gray-500 mt-2">Changing...</p>
        )}
      </div>

      {/* Trusted Sources */}
      <div>
        <h3 className="text-sm font-medium text-gray-700">
          Trusted Sources
        </h3>
        <ul className="space-y-2">
          {route.trustedSources &&
            route.trustedSources.map((source, index) => (
              <li
                key={index}
                className="flex items-center justify-between p-2 bg-gray-100 rounded"
              >
                <span className="text-gray-600 truncate">{source}</span>
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-red-600 hover:text-red-800"
                  onClick={() => deleteSource(source)}
                >
                  Delete
                </Button>
              </li>
            ))}
        </ul>
        <form
          className="mt-4"
          onSubmit={(e) => {
            e.preventDefault();
            addSource(state.newSource);
            dispatch({ type: "CLEAR_NEW_SOURCE" });
          }}
        >
            <div className="relative mt-4">
              <label
                htmlFor="add-trusted-source"
                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
              >
                Add URL or IP
              </label>
              <Input
                id="add-trusted-source"
                type="text"
                placeholder="https://mydomain.com"
                value={state.newSource}
                onChange={(e) =>
                  dispatch({ type: "SET_NEW_SOURCE", payload: e.target.value })
                }
                className="rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600 w-full"
              />
            </div>
          <Button
            type="submit"
            className="flex items-center justify-center px-4 py-2 mt-6 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700"
          >
            Add Trusted Source +
          </Button>
        </form>
      </div>
    </Panel>
  );
}
