import {
  VISIBLE_LOGGED_IN_ONLY,
  VISIBLE_LOGGED_OUT_ONLY,
} from "@tmavs/reactthings/lib/components/NavBar";
import { AuthProvider } from "@tmavs/reactthings/lib/contexts/AuthContext";
import { ConstantsProvider } from "@tmavs/reactthings/lib/contexts/ConstantsContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { RouterProvider } from "react-router-dom";
import Logo from "./common/components/Logo";
import { useFirebase } from "./common/contexts/FirebaseContext";
import "./index.css";
import router from "./Router";

function App() {
  const hasSignedUp = localStorage.getItem("hasSignedUp") === "true";

  const linkSignup = hasSignedUp
    ? {
        caption: "Login",
        link: "/login",
        target: "",
        visible: VISIBLE_LOGGED_OUT_ONLY,
      }
    : {
        caption: "Signup",
        link: "/signup",
        target: "",
        visible: VISIBLE_LOGGED_OUT_ONLY,
      };

  const links = [
    {
      caption: "Routes",
      link: "/my/routes",
      target: "",
      visible: VISIBLE_LOGGED_IN_ONLY,
    },
    {
      caption: "Billing",
      link: "/billing",
      target: "",
      visible: VISIBLE_LOGGED_IN_ONLY,
    },
    {
      caption: "Logout",
      link: "/logout",
      target: "",
      visible: VISIBLE_LOGGED_IN_ONLY,
    },
    linkSignup,
  ];

  const { auth, firestore } = useFirebase();
  async function checkSubscriptionForUserId(userId) {
    if (userId) {
      localStorage.setItem("hasSignedUp", "true");
    }
    try {
      // Check subscription status
      console.log("Get Subscription details for " + userId);
      const subscriptionsRef = collection(
        firestore,
        `customers/${userId}/subscriptions`
      );

      // Create a query to find active subscriptions with the specific product
      const q = query(subscriptionsRef, where("status", "==", "active"));

      const querySnapshot = await getDocs(q);

      // Check if any active subscription exists
      return !querySnapshot.empty; // Returns true if there are any matching documents
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    } finally {
      console.log("Checked for subscription status complete");
    }
    return false;
  }
  return (
    <ConstantsProvider links={links} Logo={Logo}>
      <AuthProvider
        auth={auth}
        checkSubscriptionForUserId={checkSubscriptionForUserId}
      >
        <div className="center-content">
          <RouterProvider router={router} />
        </div>
      </AuthProvider>
    </ConstantsProvider>
  );
}

export default App;
