import _logo from "./img/logo_full.png";
export const SUCCESS_REDIRECT_URL = "/my/routes";

export const STRIPE_PORTAL_ID = ""; // Optional ID of a portal configuration
export const STRIPE_MONTHLY_PRICE_ID = "price_1QM3P9IwkAI8JQRm4KVvA4Do";
export const STRIPE_ANNUAL_PRICE_ID = "";
export const STRIPE_TOKEN_PRICE_ID = "price_1QM3P9IwkAI8JQRm4KVvA4Do";
export const LOGO = _logo;
export const MAX_COLLECTIONS = {
  subscribed: 20,
  unsubscribed: 2,
};
export const MAX_ITEMS = {
  subscribed: 30,
  unsubscribed: 10,
};

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyD3QRQz0LJjrtStuVUHXsYL3Rm6znLwn6Q",
  authDomain: "datatruck-255d7.firebaseapp.com",
  projectId: "datatruck-255d7",
  storageBucket: "datatruck-255d7.firebasestorage.app",
  messagingSenderId: "90056123293",
  appId: "1:90056123293:web:7b570eee87c0ba9a264574",
};
