import React, { useState } from "react";
import Panel from "@tmavs/reactthings/lib/layouts/Panel";
import { Button } from "../ui/Button";

// Collapsible Content Component
function CollapsibleContent({ data, className }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-indigo-600 text-sm font-medium hover:underline"
      >
        {isOpen ? "Hide Details" : "Show Details"}
      </button>
      {isOpen && (
        <pre className={`${className} mt-2`}>
          {JSON.stringify(data, null, 2)}
        </pre>
      )}
    </div>
  );
}

// LastDataPanel Component
export default function LastDataPanel({ className = "", route, addTrustedSource }) {
  return (
    <Panel header="Last Test Request" className={className}>
      {!route.lastDataInTimeStamp && (
        <div className="text-center p-4">
          <h3 className="text-lg font-medium text-gray-800">
            Waiting for test data
          </h3>
          <p className="text-sm text-gray-600 mt-2">
            Need to call the API listed by URI with your service.
          </p>
        </div>
      )}
      {route.lastDataInTimeStamp && (
        <div className="p-6 space-y-6">
          {/* Timestamp */}
          <div className="text-sm text-gray-700">
            <p>
              <span className="font-medium">When:</span>{" "}
              {new Date(route.lastDataInTimeStamp.seconds * 1000).toLocaleString()}
            </p>
          </div>

          {/* Source */}
          <div className="text-sm text-gray-700">
            <p>
              <span className="font-medium">Source:</span> {route.lastDataSource}
            </p>
            {(!route.trustedSources ||
              !route.trustedSources.includes(route.lastDataSource)) && (
              <Button
                className="mt-2 text-indigo-600 hover:text-indigo-800"
                onClick={() => addTrustedSource(route.lastDataSource)}
              >
                Add to Trusted Source
              </Button>
            )}
          </div>

          {/* Form Data */}
          <div>
            <h4 className="text-md font-medium text-gray-800 mb-4">Form Data</h4>
            <div className="bg-gray-100 p-6 rounded-md space-y-4 max-h-[400px] overflow-y-auto">
              {Object.entries(JSON.parse(route.lastDataInData)).map(([key, value], index) => (
                <div key={index} className="flex flex-col items-start space-y-2">
                  <span className="font-medium text-gray-700">{key}:</span>
                  {typeof value === "object" && value !== null ? (
                    Array.isArray(value) ? (
                      <pre className="bg-gray-200 p-2 rounded text-sm overflow-x-auto whitespace-pre-wrap">
                        {JSON.stringify(value, null, 2)}
                      </pre>
                    ) : (
                      <CollapsibleContent
                        data={value}
                        className="bg-gray-200 p-2 rounded text-sm overflow-x-auto whitespace-pre-wrap"
                      />
                    )
                  ) : typeof value === "string" && value.match(/^https?:\/\//) ? (
                    <a
                      href={value}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-600 underline break-words"
                    >
                      {value}
                    </a>
                  ) : value === null || value === undefined ? (
                    <span className="text-gray-500 italic">Empty</span>
                  ) : (
                    <span className="text-gray-700 break-words">{value}</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Panel>
  );
}