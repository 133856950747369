export function viewMyItem(collectionid) {
  return `/my/routes/${collectionid}`;
}

export function viewAllOfMyItems() {
  return `/my/routes`;
}

export function viewPublicCollection(collectionid) {
  return `/p/routes/${collectionid}`;
}
