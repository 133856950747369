// src/index.js
import { initializeApp } from "firebase/app";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { FirebaseProvider } from "./common/contexts/FirebaseContext";
import { FIREBASE_CONFIG } from "./constants";
import "./index.css"; // Ensure Tailwind CSS is imported

const root = ReactDOM.createRoot(document.getElementById("root"));
const app = initializeApp(FIREBASE_CONFIG);
root.render(
  <FirebaseProvider app={app}>
    <App />
  </FirebaseProvider>
);
