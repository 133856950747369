import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { useFirebase } from "../common/contexts/FirebaseContext";
import { Button } from "../ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/Dialog";
import { Input } from "../ui/Input";

export default function AddRouteModel({ isOpen, routes, onFinish }) {
  const { auth, firestore } = useFirebase();

  const handleAdd = async (newItem) => {
    const user = auth.currentUser;
    if (user) {
      const maxOrder =
        routes.length > 0 ? Math.max(...routes.map((c) => c.order ?? 0)) : 0;

      await addDoc(collection(firestore, "routes"), {
        ...newItem,
        userId: user.uid,
        order: maxOrder + 1, // Assign the next order value
      });
    }
  };

  const [name, setName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    const user = auth.currentUser;
    if (!user) {
      alert("You must be logged in to create a collection.");
      return;
    }

    const newRoute = {
      name,
      createdAt: new Date(),
      userId: user.uid,
    };

    await handleAdd(newRoute);
    onFinish();
    setName("");
    setIsSaving(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Route</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 py-4">
            <div className="relative">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
              >
                Route Name <span className="text-red-500">*</span>
              </label>
              <Input
                id="name"
                name="name"
                required
                aria-required="true"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={21}
                className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
              />
              <p className="text-xs text-gray-500 mt-1 text-right">
                {21 - name.length} characters remaining
              </p>
            </div>
          </div>

          {/* Close and Save Buttons */}
          <div className="flex justify-end space-x-4">
            <Button variant="ghost" onClick={onFinish}>
              Close
            </Button>
            <Button type="submit">{isSaving ? "Saving.." : "Save"}</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
