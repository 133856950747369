// src/components/Auth/Signup.js
import { AuthError } from "@tmavs/reactthings";
import AuthPageLayout from "@tmavs/reactthings/lib/layouts/AuthPageLayout";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SUCCESS_REDIRECT_URL } from "../../../constants";
import { useFirebase } from "../../contexts/FirebaseContext";

export default function Signup() {
  const { auth, firestore } = useFirebase();

  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }
    try {
      setError("");
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );
      const user = userCredential.user;
      // Create account data in Firestore
      await setDoc(doc(firestore, "accounts", user.uid), {
        admins: [user.email],
        teamMembers: [],
      });
      navigate(SUCCESS_REDIRECT_URL);
    } catch (error) {
      setError("Failed to create an account: " + error.message);
      console.error("Error creating account:", error);
    }
  }

  return (
    <AuthPageLayout header="Sign Up">
      {/* Error Alert */}
      <AuthError error={error} />

      <form onSubmit={handleSubmit}>
        {/* Email Input */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 mb-2">
            Email
          </label>
          <input
            type="email"
            ref={emailRef}
            required
            className="w-full p-3 border border-gray-300 rounded"
          />
        </div>

        {/* Password Input */}
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 mb-2">
            Password
          </label>
          <input
            type="password"
            ref={passwordRef}
            required
            className="w-full p-3 border border-gray-300 rounded"
          />
        </div>

        {/* Confirm Password Input */}
        <div className="mb-6">
          <label htmlFor="passwordConfirm" className="block text-gray-700 mb-2">
            Confirm Password
          </label>
          <input
            type="password"
            ref={passwordConfirmRef}
            required
            className="w-full p-3 border border-gray-300 rounded"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition-colors"
        >
          Sign Up
        </button>
      </form>

      {/* Link to Login */}
      <div className="mt-6 text-center">
        Already have an account?{" "}
        <Link to="/login" className="text-blue-500 hover:underline">
          Log In
        </Link>
      </div>
    </AuthPageLayout>
  );
}
