import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { viewMyItem } from "../utils/Links";
export default function Selector({
  className,
  pickText = "Select one",
  emptySearchText = "Search",
  items,
  selectedId,
  children,
}) {
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const searchRef = useRef(null);

  useEffect(() => {
    setDropdownOpen(false);
  }, [selectedId]);

  const selectedItemText = selectedId
    ? items.find((i) => i.id === selectedId)?.name
    : pickText;

  const filteredItems = items.filter(
    (singleItem) =>
      searchText === "" ||
      singleItem.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <div className={`z-30 relative w-64 bg-white shadow-md p-4 ${className}`}>
        <div
          className="w-full flex"
          onClick={() => {
            if (!isDropdownOpen) {
              setDropdownOpen(true);
              searchRef.current.focus();
            }
          }}
        >
          <span className="flex-1">
            <input
              type="text"
              ref={searchRef}
              value={isDropdownOpen ? searchText : selectedItemText}
              placeholder={emptySearchText}
              className="w-full"
              onChange={(e) => setSearchText(e.target.value)}
              maxLength={12}
            />
          </span>
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
            onClick={(e) => {
              if (!isDropdownOpen) return;
              e.preventDefault();
              e.stopPropagation();
              setDropdownOpen(false);
            }}
          />
        </div>

        {isDropdownOpen && (
          <div className="dropdown-open">
            <ul className="space-y-2">
              {filteredItems.length === 0 && items.length > 0
                ? `Nothing found searching for '${searchText}'`
                : filteredItems.map((singleItem) => (
                    <li
                      key={singleItem.id}
                      className="flex flex-wrap items-start justify-between p-2 bg-gray-100 rounded-md shadow-sm relative"
                    >
                      <Button
                        variant="ghost"
                        className="flex-grow justify-start text-left break-words"
                        onClick={() => {
                          navigate(viewMyItem(singleItem.id));
                          setDropdownOpen(false);
                        }}
                      >
                        <span className="break-words">{singleItem.name}</span>
                        {singleItem.isPublic && (
                          <GlobeAltIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                        )}
                        {singleItem.id === selectedId && (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </Button>
                    </li>
                  ))}
            </ul>
            {children}
          </div>
        )}
      </div>
      {isDropdownOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full z-10"
          onClick={() => setDropdownOpen(false)}
        />
      )}
    </>
  );
}
