import React from 'react';

// Constants
const ABOUT_HEADING = "What is Datatruck?";
const SUBHEADING_TEXT = "Datatruck is the tool you didn’t know you needed. It takes the hassle out of handling forms, emails, and files, making sure everything gets where it needs to go—quickly and effortlessly.";


const FEATURES = [
  {
    name: 'Send Anything',
    description: 'Forms, emails, files, or photos—Datatruck takes it all off your hands.',
  },
  {
    name: 'Your AI Agent Does the Hard Work',
    description: 'No sorting, no organising—just send it, and let Datatruck do the rest.',
  },
  {
    name: 'Delivered Where You Need It',
    description: 'Choose the destination, and your data shows up ready to use.',
  },
  {
    name: 'Say Goodbye to Clutter',
    description: 'Datatruck keeps everything organised, so you don’t have to.',
  },
  {
    name: 'Save Time, Every Time',
    description: 'Datatruck frees up your time by handling the busywork.',
  },
  {
    name: 'Everything Just Works',
    description: 'No missing details. No stress. Just simple, clear results.',
  },
];

const HEADING_ALIGNMENT = "text-center";
const SUBHEADING_ALIGNMENT = "text-center";

const FeaturesTextCards = () => {
  return (
    <section
      id="features"
      className="relative py-64 sm:py-48 pt-24 bg-gradient-to-b bg-gradient-to-t from-gray-100 to-white"
    >
      <div className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h1
            className={`mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ${HEADING_ALIGNMENT}`}
          >
            {ABOUT_HEADING}
          </h1>
          <p
            className={`mt-6 text-lg leading-8 text-gray-500 ${SUBHEADING_ALIGNMENT}`}
          >
            {SUBHEADING_TEXT}
          </p>
        </div>
        <div className="relative mx-auto mt-16 mb-8 flex flex-wrap justify-center gap-6">
          {FEATURES.map((feature) => (
            <div
              key={feature.name}
              className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70 w-full max-w-xs"
            >
              <div className="p-4 md:p-7">
                <h3 className="text-lg font-bold text-gray-900 dark:text-white">
                  {feature.name}
                </h3>
                <p
                  className="mt-2 text-gray-900 dark:text-neutral-400 whitespace-pre-line"
                >
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesTextCards;