import { FolderOpenIcon } from "@heroicons/react/24/outline";
import Ad from "@tmavs/reactthings/lib/components/Ad";
import AppPageLayout from "@tmavs/reactthings/lib/layouts/AppPageLayout";
import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirebase } from "../common/contexts/FirebaseContext";
import AddRouteModal from "../components/AddRouteModal";
import EditRouteModal from "../components/EditRouteModal";
import Selector from "../components/Selector";
import Sidebar from "../components/Sidebar";
import RouteContainer from "../containers/RouteContainer";
import { Button } from "../ui/Button";
import {
  MAIN_COLLECTION_NAME,
  userCollectionQuery,
} from "../utils/FirebaseUtils";
import { viewAllOfMyItems, viewMyItem } from "../utils/Links";

// Define the initial state
const initialState = {
  collections: [],
  isCreating: false,
  editingCollection: null,
  collectionsLoading: true,
};

const ACTION_CREATE_OPEN = { type: "ACTION_CREATE_OPEN", payload: true };
const ACTION_CREATE_CLOSE = { type: "ACTION_CREATE_OPEN", payload: false };
const ACTION_CANCEL_EDIT = { type: "SET_CANCEL_EDITING" };

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COLLECTIONS":
      return {
        ...state,
        collections: action.payload,
        collectionsLoading: false,
        editingCollection: null,
      };
    case "ACTION_CREATE_OPEN":
      return { ...state, isCreating: action.payload };
    case "SET_EDITING_COLLECTION":
      return { ...state, editingCollection: action.payload };
    case "ACTION_CANCEL_EDIT":
      return { ...state, editingCollection: null };
    default:
      return state;
  }
};

export default function RoutesPage() {
  const { auth, firestore } = useFirebase();
  const { routeId } = useParams();
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { collections, isCreating, editingCollection, collectionsLoading } =
    state;

  const [isFormBuilderOpen, setFormBuilderOpen] = useState(false); // Track FormBuilder modal state

  const newRouteButton = (
    <Button
      className="w-full mt-4"
      onClick={() => dispatch(ACTION_CREATE_OPEN)}
    >
      New Route
    </Button>
  );

  // Fetch collections from Firestore
  useEffect(() => {
    const query = userCollectionQuery(firestore, user.uid);
    const unsubscribe = onSnapshot(query, (snapshot) => {
      const loadedCollections = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch({ type: "SET_COLLECTIONS", payload: loadedCollections });

      if (!routeId && loadedCollections.length > 0) {
        navigate(viewMyItem(loadedCollections[0].id), { replace: true });
      }
    });

    return () => unsubscribe();
  }, [user.uid, firestore, routeId]);

  const handleUpdateRoute = async (updatedItem) => {
    try {
      const collectionRef = doc(
        firestore,
        MAIN_COLLECTION_NAME,
        updatedItem.id
      );
      await updateDoc(collectionRef, updatedItem);
      dispatch({ type: "SET_EDITING_COLLECTION", payload: null });
    } catch (error) {
      console.error("Error updating:", error);
    }
  };

  // Delete collection
  const handleDeleteCollection = async (deleteRouteId) => {
    try {
      const collectionDocRef = doc(
        firestore,
        MAIN_COLLECTION_NAME,
        deleteRouteId
      );

      // Delete the collection
      await deleteDoc(collectionDocRef);

      // Update the local state
      dispatch({
        type: "SET_COLLECTIONS",
        payload: collections.filter((c) => c.id !== deleteRouteId),
      });

      // If the deleted collection was the selected one, reset selection
      if (routeId === deleteRouteId) {
        navigate(viewAllOfMyItems(), { replace: true });
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const selectedRoute = collections.find((c) => c.id === routeId);

  return (
    <AppPageLayout>
      {/* Desktop Sidebar */}
      <div className="hidden md:block">
        <Sidebar title="Routes" items={collections}>
          {newRouteButton}
        </Sidebar>
      </div>

      {collectionsLoading && (
        <h2 className="text-4xl text-center flex-1 font-bold text-gray-800 mb-4">
          Loading...
        </h2>
      )}

      {/* Main Content Area */}
        {!collectionsLoading && (
          <div className="flex-1 p-4 md:p-8 overflow-auto">
            {selectedRoute ? (
              <>
                <div className="flex flex-col justify-between items-center mb-4">
                  <div className="w-full flex justify-between items-center">
                    <h1 className="text-2xl md:text-3xl font-bold text-gray-800 hidden md:block">
                      {selectedRoute.name}
                    </h1>
                    <Selector
                      className={"block md:hidden"}
                      items={collections}
                      pickText="Select a route"
                      selectedId={routeId}
                    >
                      {newRouteButton}
                    </Selector>
                    <div className="flex space-x-2">
                      <Button
                        variant="ghost"
                        onClick={() => {
                          const collection = collections.find(
                            (c) => c.id === routeId
                          );
                          if (collection) {
                            dispatch({
                              type: "SET_EDITING_COLLECTION",
                              payload: collection,
                            });
                          }
                        }}
                      >
                        Dangerous Stuff
                      </Button>
                    </div>
                  </div>
                  <div className="flex-1 w-full" style={{ marginTop: "20px" }}>
                    <RouteContainer
                      route={selectedRoute}
                      updateRoute={handleUpdateRoute}
                    />
                  </div>
                </div>
              </>
            ) : (
              // Default view when no collection is selected
              <div className="flex flex-col items-center justify-center h-full">
                {collections.length > 0 && (
                  <Selector
                    className={"block md:hidden"}
                    items={collections}
                    selectedId={routeId}
                    pickText="Select a route"
                  >
                    {newRouteButton}
                  </Selector>
                )}
                <FolderOpenIcon className="h-24 w-24 text-gray-400 mb-4" />
                <h2 className="text-2xl font-bold text-gray-700 mb-2">
                  No Route Selected
                </h2>
                <p className="text-gray-500 text-center mb-4">
                  Let's get started
                </p>
                <Button onClick={() => dispatch(ACTION_CREATE_OPEN)}>
                  Make your first route
                </Button>
              </div>
            )}

            {/* Ad Section */}
            <div className="mt-9">
              <Ad />
            </div>
          </div>
        )}

      {/* Modals */}
      <AddRouteModal
        isOpen={isCreating}
        onFinish={() => dispatch(ACTION_CREATE_CLOSE)}
        routes={collections}
      />
      <EditRouteModal
        isOpen={editingCollection !== null}
        onClose={() => dispatch(ACTION_CANCEL_EDIT)}
        collection={editingCollection}
        onSaveCollection={handleUpdateRoute}
        onDeleteCollection={handleDeleteCollection}
      />

    </AppPageLayout>
  );
}