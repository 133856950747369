// import { ClipboardIcon } from "@heroicons/react/24/outline";
// import { doc, updateDoc } from "firebase/firestore";
// import { useEffect, useState } from "react";
// import { Button } from "../ui/Button";
// import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/Dialog";
import { Input } from "../ui/Input";
// import { Label } from "../ui/Label";
// import { Switch } from "../ui/Switch";
// import { MAIN_COLLECTION_NAME } from "../utils/FirebaseUtils";
import Panel from "@tmavs/reactthings/lib/layouts/Panel";
import { Button } from "../ui/Button";

import { useReducer } from "react";
import LastDataPanel from "../components/LastDataPanel";
import APIDetailsContainer from "./APIDetailsPanel";

// Define the initial state and reducer for managing test mode
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PRIMARY_EMAIL":
      return { ...state, primaryToEmail: action.payload };
    case "SET_ADDITIONAL_EMAILS":
      return { ...state, additionalEmails: action.payload };
    case "SET_ADDITIONAL_EMAIL":
      return {
        ...state,
        additionalEmails: [
          ...state.additionalEmails,
          { subject: "", email: "" },
        ],
      };
    default:
      return state;
  }
};

export default function RouteContainer({ route, updateRoute }) {
  const initialState = {
    primaryToEmail: route.primaryToEmail || "", // Pull from route.primaryToEmail
    additionalEmails: route.additionalEmails || [], // New state for additional emails
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAddEmail = () => {
    if (state.additionalEmails.length >= 5) {
      alert("You can only add up to 5 additional emails.");
      return;
    }
    dispatch({ type: "SET_ADDITIONAL_EMAIL" });
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...state.additionalEmails];
    updatedEmails[index] = value; // Update the specific email
    dispatch({ type: "SET_ADDITIONAL_EMAILS", payload: updatedEmails });
  };

  const handleDeleteEmail = (index) => {
    const updatedEmails = state.additionalEmails.filter((_, i) => i !== index); // Remove email
    dispatch({ type: "SET_ADDITIONAL_EMAILS", payload: updatedEmails });
  };

  const handleSaveForm = (e) => {
    e.preventDefault();
    updateRoute({
      ...route,
      primaryToEmail: state.primaryToEmail,
      additionalEmails: state.additionalEmails,
    });
    alert("saved");
  };

  const handlePrimaryToEmailChange = (event) => {
    const newPrimaryToEmail = event.target.value;
    dispatch({ type: "SET_PRIMARY_EMAIL", payload: newPrimaryToEmail });
  };

  const addSource = (source) => {
    const existingSources = route.trustedSources || [];
    const updatedSources = [...existingSources, source]; // Add new source
    updateRoute({ ...route, trustedSources: updatedSources }); // Update route with new trusted sources
  };

  return (
    <div className="">
      <div className="flex flex-wrap justify-between">
        {/* API Details Panel */}
        <div className="basis-6/12 w-full p-2">
          <APIDetailsContainer
            className="bg-white p-8 rounded-lg shadow-lg w-1/2"
            route={route}
            updateRoute={updateRoute}
            addSource={addSource}
          />
        </div>
        <div className="w-full p-2 md:basis-6/12">        
        <Panel
            header="Email Details"
            className="bg-white p-8 rounded-lg shadow-lg p-6 shadow rounded-md basis-6/12"
          >
            {!route.lastDataInTimeStamp && <div>⬅Do step 1 first</div>}
            {route.lastDataInTimeStamp && (
              <form
                onSubmit={handleSaveForm}
                className="space-y-6"
              >
                {/* Primary To Email */}
                <div className="relative w-full md:w-1/2">
                  <label
                    htmlFor="to-email"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    To <span className="text-red-500">*</span>
                  </label>
                  <Input
                    id="to-email"
                    name="toEmail"
                    type="email"
                    value={state.primaryToEmail}
                    onChange={handlePrimaryToEmailChange}
                    required
                    aria-required="true"
                    placeholder="Enter recipient's email"
                    className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
                  />
                </div>

                {/* Conditional Emails Section */}
                <div>
                  <h2 className="text-lg font-medium">Conditional Emails</h2>
                  <p className="text-sm text-gray-600 mb-6 mt-1">
                    Let our AI automatically CC others based on the content of the form
                  </p>
                  {state.additionalEmails.map((emailObj, index) => (
                    <div
                      key={index}
                      className="flex flex-wrap gap-4 mb-4"
                    >
                      {/* Email Subject */}
                      <div className="relative w-full md:flex-1">
                        <label
                          htmlFor={`email-subject-${index}`}
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Topic
                        </label>
                        <Input
                          id={`email-subject-${index}`}
                          name="emailSubject"
                          type="text"
                          value={emailObj.subject}
                          onChange={(e) =>
                            handleEmailChange(index, {
                              ...emailObj,
                              subject: e.target.value,
                            })
                          }
                          placeholder="Sales enquiry"
                          className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
                        />
                      </div>

                      {/* Conditional Email */}
                      <div className="relative w-full md:flex-1">
                        <label
                          htmlFor={`conditional-email-${index}`}
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          CC email
                        </label>
                        <Input
                          id={`conditional-email-${index}`}
                          name="conditionalEmail"
                          type="email"
                          value={emailObj.email}
                          onChange={(e) =>
                            handleEmailChange(index, {
                              ...emailObj,
                              email: e.target.value,
                            })
                          }
                          placeholder="sales@datatruck.cc"
                          className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
                        />
                      </div>

                      {/* Delete Button */}
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        className="text-red-500"
                        onClick={() => handleDeleteEmail(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  ))}

                  {/* Add Email Button */}
                  {state.additionalEmails.length < 5 && (
                    <div>
                      <Button
                        type="button"
                        onClick={handleAddEmail}
                        className="flex items-center justify-center px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700"
                      >
                        Add Email +
                      </Button>
                    </div>
                  )}
                </div>

                {/* Divider */}
                <hr className="my-6 border-gray-300" />

                {/* Save Button */}
                <Button
                  type="submit"
                  className="px-4 py-2 text-white bg-green-600 rounded-md hover:bg-green-700"
                >
                  Save
                </Button>
              </form>
            )}
          </Panel>
        </div>
        <div className="basis-12/12 w-full p-2">
          <LastDataPanel
            className="bg-white p-8 rounded-lg shadow-lg"
            route={route}
            addTrustedSource={addSource}
          />
        </div>
      </div>
    </div>
  );
}
