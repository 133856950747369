import AppPageLayout from '@tmavs/reactthings/lib/layouts/AppPageLayout.js';
import Panel from '@tmavs/reactthings/lib/layouts/Panel.js';
import React from 'react';
import FeaturesTextCards from '../common/components/FeaturesTextCards.jsx';
import HeroImageSplitScreen from '../common/components/HeroImageSplitScreen.jsx';
import HeroVideoSplitScreen from '../common/components/HeroVideoSplitScreen.jsx';
import SocialProofCarousel from '../common/components/SocialProofCarousel.jsx';
import SingleCardPricing from '../common/components/SingleCardPricing.jsx';

function LandingPage() {
  return (
    <AppPageLayout>
      <Panel>
        <HeroImageSplitScreen />
        <SocialProofCarousel companies={['reddit.com', 'hackernews.com', 'producthunt.com', 'linkedin.com']} />
        <FeaturesTextCards />        
        <HeroVideoSplitScreen />
        <SingleCardPricing />  
      </Panel>
    </AppPageLayout>
  );
}

export default LandingPage;