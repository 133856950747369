// src/contexts/FirebaseContext.js
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import React, { useContext } from "react";

const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function FirebaseProvider({ app, children }) {
  let auth,
    firestore,
    storage,
    functions = null;

  if (app) {
    try {
      auth = getAuth(app);
    } catch (e) {
      console.warn("firebase auth unavailable");
    }
    try {
      firestore = getFirestore(app);
    } catch (e) {
      console.warn("firebase firestore unavailable");
    }
    try {
      storage = getStorage(app);
    } catch (e) {
      console.warn("firebase storage unavailable");
    }
    try {
      functions = getFunctions(app);
    } catch (e) {
      console.warn("firebase functions unavailable");
    }
  } else {
    console.log("firebase not ready....");
  }

  return (
    <FirebaseContext.Provider
      value={{
        app: app,
        auth: auth,
        firestore: firestore,
        functions: functions,
        storage: storage,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}
