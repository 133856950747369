import React from 'react';
import banner from '../../img/banner.png';

const HEADLINE_TEXT = "Make Your Data Work for You";
const SUBTITLE_TEXT = "Think of Datatruck as your personal AI Agent for handling any kind of data. Whether it’s a form someone filled out, an image you uploaded, or important information from an email, Datatruck reads it, organises it, and gets it where it needs to go—saving you time and effort.";

const HeroImageSplitScreen = () => {
  return (
    <div className="relative isolate overflow-hidden bg-white">
      {/* Grid Background */}
      <svg
        aria-hidden="true"
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      >
        <defs>
          <pattern
            id="grid-pattern"
            width={200}
            height={200}
            patternUnits="userSpaceOnUse"
            x="50%"
            y={-1}
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect fill="url(#grid-pattern)" width="100%" height="100%" strokeWidth={0} />
      </svg>

      {/* Hero Section Content */}
      <div className="relative py-24 sm:py-32 lg:pb-40">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              {HEADLINE_TEXT}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-900">
              {SUBTITLE_TEXT}
            </p>
          </div>
          <div className="mt-16 flow-root sm:mt-24">
            <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                alt="App screenshot"
                src={banner}
                className="rounded-md shadow-2xl ring-1 ring-gray-900/10 max-h-[500px] object-cover w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImageSplitScreen;